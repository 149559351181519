import React, { useState } from 'react'

import Slider from 'react-slick'
import styled from 'styled-components'

import { ToggleSwitch, } from '../'
import { useWindowSize } from '../../../_helpers'
import colors from '../../../css/colors.scss'
import { PrimaryButton, UpperHeader, Grid, devices } from '../../Common'


export const Tickets = ({ title, tickets }) => {
    const [weekday, setWeekday] = useState(false)
    const changePrice = () => setWeekday(!weekday)
    const { width } = useWindowSize()

    return (
        <TicketContainer>
            <h2>{title}</h2>
            <ToggleSwitchWrap>
                <ToggleSwitch name='tickets_switch' label1='Friday–Sunday' label2='Monday–Thursday' callBack={changePrice} />
                <PricingDays>{weekday ? 'Discount Days' : 'Standard Pricing'}</PricingDays>
            </ToggleSwitchWrap>
            <StyledCarousel
                slidesToShow={width > 740 ? 3 : 1}
                nextArrow={<Next />}
                prevArrow={<Previous />}
                infinite={false}
                adaptiveHeight={true}
                touchThreshold={20}
            >
                {tickets.filter(t => weekday ? t.weekday_price : t.weekend_price).map(t => (
                    <Ticket key={t.title}>
                        <UpperHeader marginbottom={20}>{t.title}</UpperHeader>
                        <Price>${weekday ? t.weekday_price : t.weekend_price}</Price>
                        <div dangerouslySetInnerHTML={{__html: t.description}} />
                    </Ticket>
                ))}
            </StyledCarousel>
            <PurchaseButton as={'a'} href='https://secure.hbg.org/events' margintop={20} marginbottom={20}>
                Get Tickets
            </PurchaseButton>
        </TicketContainer>
    )
}

const TicketContainer = styled.div`
    @media ${devices.small} {
        display: flex;
        flex-direction: column;
    }
`

const ToggleSwitchWrap = styled(Grid)`
    align-items: center;
    justify-content: center;
    position: relative;

    @media ${devices.small} {
        margin-top: 20px;
    }
`

const PricingDays = styled.div`
    width: 100%;
    position: absolute;
    top: 50px;
    z-index: -1;
    font-size: 15px;
    text-align: center;
    font-style: italic;
    color: ${colors.darkGreen};
    opacity: .7;
`

const Price = styled.h2``

const Ticket = styled.div`
    text-align: center;
    padding: 40px 20px;
    border-right: 1px solid ${colors.darkGreen};

    @media ${devices.small} {
        border-right: 0;
    }
`

const PurchaseButton = styled(PrimaryButton)`
    float: right;
    @media ${devices.small} {
        float: none;
        margin: 0 auto;
    }
`

const Previous = styled.div.attrs(({ disabled }) => ({
    style: {
        cursor: disabled ? 'default' : 'pointer',
        opacity: disabled ? 0.5 : 1,
    }
}))`
    height: 38px;
    width: 20px;
    background: url(/static/images/sprite.svg) -119px -504px;
    position: absolute;
    left: unset;
    right: 96px;
    top: -60px;

    @media ${devices.small} {
        top: 50%;
        right: 95%;
        z-index: 1;
    }

    &.slick-disabled {
        opacity: 0.3 !important;
        cursor: default !important;
    }
`

const Next = styled(Previous)`
    background-position: -168px -504px;
    right: 50px;
    @media ${devices.small} {
        right: 0;
    }
`

const StyledCarousel = styled(Slider)`
    height: ${({ height = 'auto' }) => height};
    margin-top: 40px;
    margin-bottom: 30px;

    div.slick-slide {
        display: flex;
        background: transparent;
        max-height: 350px;

        div {
            width: 100%;
        }
    }

    div.slick-current + div.slick-active + div.slick-active ${Ticket} {
        border-right: 0;
    }

    div.slick-active + div.slick-active:not(.slick-active) ${Ticket} {
        border-right: 0;
    }

    .slick-list {
        /* // Allow for all slides to be the same height */
        display: flex;
        /* // whithout this, slides will dissapear from the end of the carousel */
        flex-direction: column;
    }
`

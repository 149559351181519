// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"colors.scss"}]);
// Exports
exports.locals = {
	"black": "#000000",
	"white": "#FFFFFF",
	"darkGreen": "#325644",
	"red": "#ed746a",
	"blue": "#3949a4",
	"yellow": "#ffc643",
	"green": "#dceedb",
	"lightGreen": "#f6fbf6",
	"pink": "#fcdfda",
	"lightPink": "#fdefec"
};
module.exports = exports;

import React from 'react'

import {
    TextAreaWrap,
    FormLabel,
    InputContainer,
    TextArea,
    InlineFormError
} from '../../'


export const TextAreaField = ({ input, type, label, required, meta: { touched, error } }) => (
    <TextAreaWrap>
        <FormLabel>{label}{required ? '*' : ''} {touched && error && <InlineFormError>{error}</InlineFormError>}</FormLabel>
        <InputContainer>
            <TextArea { ...input } type={ type } required={required}/>
        </InputContainer>
    </TextAreaWrap>
)

import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { subscribe } from '../../_actions'
import { useFormInput } from '../../_helpers'
import colors from '../../css/colors.scss'
import {
    Container,
    Row,
    UnstyledLink,
    devices,
    TextInput,
    PrimaryButton,
} from '../Common'


const FootLink = ({ link, title }) => {
    return (
        <FooterLinkItem>
            <h4>
                <UnstyledLink as={Link} to={link}>
                    {title}
                </UnstyledLink>
            </h4>
        </FooterLinkItem>
    )
}

export const Footer = () => {
    const dispatch = useDispatch()
    // const { newsletterError, newsletterMessage, page } = useSelector(state => state.content)
    const { page } = useSelector(state => state.content)
    const [newsletter_modal, setNewsletterModal] = useState(false)

    const subscribeHandler = (e) => {
        e.preventDefault()
        if (email && email.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setNewsletterModal(true)
            dispatch(subscribe(email.value))
        }
    }

    const email = useFormInput('E-mail', '', subscribeHandler)

    return (
        <>
            <FooterWrap>
                <FooterContainer>
                    <FooterRow>
                        <FooterInfoWrap>
                            <FooterInfo>
                                <SmallHeader>Our Mission</SmallHeader>
                                <h4>
                                    Enriching life through discovery, education, and the conservation of plants and the natural environment.
                                </h4>
                            </FooterInfo>
                            <FooterInfo>
                                <SmallHeader>Hours</SmallHeader>
                                {page.hours && page.hours.map(h =>
                                    <div key={h.display}>
                                        {h.display.toUpperCase()}
                                        <br />
                                        {h.time}
                                    </div>
                                )}
                            </FooterInfo>
                            <FooterInfo>
                                <Addresses>
                                    <Address>
                                        <p>Garden Entrance</p>
                                        <a as='a' href="/visit/#directions">
                                            One Botanic Lane<br />
                                            Houston, TX 77017
                                        </a>
                                        <Email as='a' href="mailto:info@hbg.org" target="_blank">info@hbg.org</Email>
                                    </Address>
                                    <Address>
                                        <p>Mailing Address</p>
                                        <p>8205 N. Bayou Drive</p>
                                        <p>Houston, TX 77017</p>
                                    </Address>
                                </Addresses>
                            </FooterInfo>
                            <FooterInfo>
                                <Address>
                                    <p>Ticket Window</p>
                                    <a href="tel:+17137159675;100">713.715.9675 ext. 100</a>
                                </Address>
                            </FooterInfo>
                        </FooterInfoWrap>
                        <FooterMenu width={30} margintop={30}>
                            <ButtonBox>
                                <PrimaryButton as='a' href='https://secure.hbg.org/events'>Get Tickets</PrimaryButton>
                                <PrimaryButton as='a' href='https://secure.hbg.org/donate/q/annual/'>Donate Now</PrimaryButton>
                            </ButtonBox>
                            <FootLinks>
                                <FootLink title={'Rentals'} link={'/events/rentals'} />
                                <FootLink title={'Sponsors'} link={'/support/sponsor'} />
                                <FootLink title={'Press Room & Media'} link={'/about/news-and-press-resources/'} />
                                {/*<FootLink title={'Blog'} link={'#'} />*/}
                                <FootLink title={'Employment'} link={'/about/employment/'} />
                            </FootLinks>
                            <FootInputs>
                                {/* <Search label='Explore the Garden' placeholder='Type your search here' color={colors.white} /> */}
                                <Newsletter {...email} color={colors.white} label='Join Our Mailing List' type='email' required={true} placeholder='example@email.com' marginbottom={0} />
                            </FootInputs>
                        </FooterMenu>
                    </FooterRow>
                </FooterContainer>
            </FooterWrap>
            {newsletter_modal &&
                <NewsletterModal>
                    <ModalWindow>
                        <CloseButton role="button" aria-label="Houston Botanic Garden" onClick={() => setNewsletterModal(false)} />
                        <ColumnLeft>
                            <Image />
                        </ColumnLeft>
                        <ColumnRight>
                            <Logo aria-label="Houston Botanic Garden" />
                            <h2>How’s it growing?</h2>
                            <p>Welcome and thank you for subscribing to our newsletter. Now you can stay up-to-date on all the latest happenings at the Houston Botanic Garden.</p>
                        </ColumnRight>
                    </ModalWindow>
                </NewsletterModal>
            }
        </>
    )
}

const FooterWrap = styled.footer`
    padding-top: 50px;
    padding-bottom: 85px;
    background: ${colors.darkGreen};
    color: ${colors.white};
    @media ${devices.small} {
        padding-bottom: 65px;
    }
`

const FooterContainer = styled(Container)``

const FooterRow = styled(Row)`
    @media ${devices.small} {
        flex-direction: column;
        padding: 0 15px;
    }
`

const FooterInfoWrap = styled.div`
    width: calc(100% - 330px);
    max-width: 540px;
    padding-right: 60px;

    @media ${devices.small} {
        width: 100%;
        max-width: 100%;
        padding-right: 0;
    }
`

const FooterMenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;

    @media ${devices.small} {
        width: 100%;
        border-top: 1px solid ${colors.white};
        padding-top: 30px;
        margin-top: 26px;
    }
`

const FooterInfo = styled.div`
    margin: 0 0 15px;

    &:last-child {
        margin: 0;
    }
`

const FootLinks = styled.div`
    margin: 25px 4px 23px;
`

const FooterLinkItem = styled.div`
    margin-bottom: 10px;

    &:last-child {
        margin: 0;
    }

    h4 {
        line-height: 1.2;
    }
`

const Address = styled.div`
    text-decoration: none;

    p:nth-child(1) {
        font-weight: bold;
    }

    a {
        display: block;
        text-decoration: none;
        color: inherit;
    }
`

const Addresses = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 360px;

    @media ${devices.mobile} {
        max-width: 100%;
    }
`

const Email = styled(UnstyledLink)`
    text-decoration: none;
    font-weight: bold;
`

const FootInputs = styled.div``

const SmallHeader = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`

const ButtonBox = styled(FooterInfo)`
    /*
    display: flex;
    align-items: center;
    justify-content: space-between;
    */
    margin-bottom: -15px;

    ${PrimaryButton} {
        display: block;
        width: 190px;
        padding-left: 25px;
        padding-right: 25px;
        margin-bottom: 15px;
    }

    @media ${devices.small} {
        flex-wrap: wrap;

        ${PrimaryButton} {
            width: 100%;

            &:last-of-type {
                margin-top: 20px;
            }
        }
    }
`

// const Search = styled(TextInput)``

const Newsletter = styled(TextInput)``

const NewsletterModal = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 70px 70px 15px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4000;
    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        background: #000;
        opacity: .8;
    }

    @media ${devices.medium_portrait} {
        padding: 70px 15px 15px;
    }
`

const ModalWindow = styled.div`
    display: flex;
    width: 100%;
    max-width: 1040px;
    position: relative;
    margin: auto;
    background: ${colors.blue};
    box-shadow: 0 1px 4px rgba(0,0,0,.3);
    color: ${colors.white};

    @media ${devices.small} {
        display: block;
        max-width: 400px;
    }
`

const CloseButton = styled.div`
    width: 45px;
    height: 45px;
    background: url('/static/images/sprite.svg') no-repeat -40px -447px;
    border: 1px solid ${colors.white};
    border-radius: 100%;
    position: absolute;
    top: -55px;
    right: -55px;
    cursor: pointer;

    @media ${devices.medium_portrait} {
        right: 0;
    }
`

const ColumnLeft = styled.div`
    width: 50%;

    @media ${devices.small} {
        width: 100%;
    }
`

const ColumnRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 85px 30px 85px;
    position: relative;
    text-align: center;
    font-size: 20px;

    h2 {
        margin: 0 0 30px;
    }

    p {
        max-width: 380px;
    }

    @media ${devices.medium_portrait} {
        padding: 85px 30px 30px;
    }

    @media ${devices.small} {
        width: 100%;
        padding: 70px 15px 20px;
        font-size: calc(20px * .8);
        h2 {
            margin: 0 0 10px;
            font-size: calc(68px * .5);
        }
    }
`

const Image = styled.div`
    width: 100%;
    height: 100%;
    background: url('/static/images/newsletter-modal.jpg') no-repeat 50% 50%/cover;
    &:before {
        content: "";
        display: block;
        height: 0;
        padding-bottom: 133.3333%;
    }

    @media ${devices.small} {
        max-height: 350px;
        &:before {
            padding-bottom: 66.6666%;
        }
    }
`

const Logo = styled.div`
    width: 212px;
    height: 28px;
    background: url('/static/images/sprite.svg') no-repeat 0 -71px/600px 600px;
    margin: 0 auto;
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;

    @media ${devices.small} {
        top: 20px;
    }
`

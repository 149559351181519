import React, { Fragment } from 'react'

import { useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'

import { Grid, UpperHeader, SecondaryButton } from '../../Common'


export const Hours = ({ title, cafe }) => {
    const { hours, hours_alert, cafe_hours, cafe_content } = useSelector(state => state.content.page, shallowEqual)
    const hoursArray = cafe ? cafe_hours : hours
    const message = cafe ? cafe_content.message : hours_alert
    return (
        <Fragment>
            <h2>{title}</h2>
            <Grid margintop={20}>
                <HourColumn>
                    {hoursArray.map(h =>
                        <Hour key={h.display}>
                            <UpperHeader>{h.display.toUpperCase()}</UpperHeader>
                            {h.time}
                        </Hour>
                    )}
                </HourColumn>
                <Grid.Column>
                    <div dangerouslySetInnerHTML={{__html: message}} />
                    {cafe && cafe_content.file && <SecondaryButton as='a' href={cafe_content.file} margintop={30} target='_blank'>Download PDF</SecondaryButton>}
                </Grid.Column>
            </Grid>
        </Fragment>
    )
}

const HourColumn = styled(Grid.Column)`
    display: flex;
    flex-direction: column;
`

const Hour = styled.div`
    display: inline-block;

    &:not(:first-of-type) {
        margin-top: 30px;
    }
`
